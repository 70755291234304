.form-outline input.form-control{
    color: #fff;
    
}
.d-flex{
    border-radius: 10px;
    
}

.mx-2 px-5{
    background-color: cadetblue;
}

.p-5{
    background: linear-gradient(90deg, #38b0c2 0%, #247ea4 51.85%, #033690 100%);
}

#formControlLg{
    border-color: blue;
}

.socialSignIn{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}

.googleText{
    margin-left: 10px;
}


