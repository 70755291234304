/* DynamicSection.css */

/* Default styles */
.dynamic-section {
  padding: 20px;
}

.headingSection {
  margin-bottom: 6vh;
  color: black;
  font-weight: 700;
}

.content {
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}

.left {
  flex-direction: row-reverse;
}

.text-content {
  padding: 20px;
  flex: 50%;

}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 50%;
}

.section-image {
  max-width: 100%;
  height: auto;
}

.requestDemoBtn {
  margin-left: 10px;
  background-color: var(--primary-color);
  height: 40px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto!important;
}

.requestDemoBtnText {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  font-family: "HelveticaNeue-Light";
}


.point-item {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

/* Grid layout for points-list */
.points-list {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 0;
}

/* Responsive styles */
@media (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
  }

  .image-container {
    order: -1;
    /* Move the image to the top in mobile view */
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .text-content {
    padding: 10px;
    text-align: center;
  }

  .ctaButton {
    width: 100%;
    text-align: center;
  }

  /* Single column grid for points-list on mobile */
  .points-list {
    grid-template-columns: 1fr;
  }
}