/* FooterStyles.css */

.footerContainer {
    margin-top: 5rem;
    background-color: #f9fafb;
    padding: 20px 0;
}

.footerContentSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 20px 0;
    flex-wrap: wrap; /* Add flex-wrap to handle wrapping on smaller screens */
}

.brandColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    flex: 1; /* Allow it to take available space */
}

.socialIcons {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    flex: 1; /* Allow it to take available space */
}

.socialIcon {
    width: 24px;
    height: 24px;
    color: #000;
    transition: color 0.3s;
}

.socialIcon:hover {
    color: #007bff;
}

hr {
    margin: 20px 0;
    border: 0;
    border-top: 2px solid #c6c6c6;
}

.copyrightContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 14px;
    flex-wrap: wrap; /* Add flex-wrap to handle wrapping on smaller screens */
}

.footerLinks {
    display: flex;
    gap: 20px;
}

.footerLinks a {
    color: #000;
    text-decoration: none;
}

.footerLinks a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 768px) {
    .footerContentSection {
        flex-direction: column;
        align-items: center;
    }

    .brandColumn, .socialIcons {
        margin-bottom: 4vw;
        text-align: center;
    }

    .socialIcons {
        gap: 10px;
        justify-content: space-around;
    }

    .copyrightContainer {
        flex-direction: column;
        text-align: center;
    }

    .footerLinks {
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }
}
