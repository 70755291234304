.pricing-card-container {
    display: flex;
    flex-direction: column;
}

.pricing-card {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 10px;
    flex: 1;
    border: 1px solid rgb(237, 237, 237);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.highlighted {
    background-color: var(--primary-color);
    color: white;
}

.highlighted .check-icon {
    color: white;
    border: 1px solid white;
}

.pricing-card-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(237, 237, 237);
}

.features-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.feature-item {
    display: flex;
    margin-bottom: 10px;
}

.check-container {
    /* padding: 10px; */
}

.check-icon {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    padding: 2px;
    margin-right: 10px;
    margin-left: 10px;
    font-weight: bold;
}

.contact-button {
    margin: 0 auto;
    width: 80%;
    background-color: transparent;
    padding: 10px 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 22px;
    color: black;
    background-color: white;
    font-family: "HelveticaNeue-Light";
}

.contact-button:hover {
    background-color: var(--primary-color);
    color: white;
}

@media (max-width: 768px) {
    .productsSection {
        display: flex;
        flex-direction: column;
    }
}
