.dashboardContainer{
    background-color: #0d1b34;
    height: auto;
}

.row.adminContainer{
    width: 80%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    margin-left: 10rem;
    color: #fff;
}

.sectionRow headingRow row{
    padding: 20px;
}

.row.adminContainer h1{

    margin-top: 20px;
}

.row.adminContainer h3{

    margin-top: 20px;
}
