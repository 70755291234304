.contactUsHeading{
    color: rgb(255,255,255, 1) !important;
}

.card-body{
    display: flex;
    justify-content: center;
}

.messageInput{
    height: 140px;
    color: #fff !important;
}

.messageInputLabel{
    color: #fff !important;
}

.sendButton{
    margin-top: 20px !important;
}
