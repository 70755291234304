.homePageContainer {
    background-color: '#fff';
    font-family: "Lora", serif;
}

.banner {
    /* height: 75vh; */
    /* display: flex; */
    font-family: "Lora", serif;
    background: linear-gradient(45deg, #14517c, #89d1e0);
    color: black;
    padding-top: 70px;
}

.banner h1 {
    text-align: center;
    font-size: 3rem;
    color: black;
    font-weight: 700;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    color: #000;
    text-align: center;
}

.heading {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 0 27vw!important;
}

.subHeading {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 40px;
    color: #000;
    padding: 0px 29vw;
}

.requestDemoBtn {
    margin-left: 10px;
    background-color: var(--primary-color);
    height: 40px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.requestDemoBtnText {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
    font-family: "HelveticaNeue-Light";
}

/* .ctaButton {
    background-color: #0033cc;
    color: white;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
} */

.sectionHeader {
    text-align: center;
    color: #000;
    padding: 4rem;
    margin: 0;
    font-weight: bold;
    padding-bottom: 0px;
}

.popularIcon {
    position: relative;
    top: 1vw;
    left: 10vw;
}

.whatItDoesBtn {
    background-color: #3B60E6 !important;
    font-size: 25px;
    font-weight: 700;
}

.pricing-section-container {
    display: flex;
    flex-direction: row;
    padding: 0 10vw;
}

@media (max-width: 768px) {
    .popularIcon {
        display: none;
    }
}

@media only screen and (max-width: 992px) {

    /* .banner {
        height: calc(100vh - 200px) !important;
    } */

    .banner h1 {
        font-size: 30px;
        margin: 20px 0;
        font-family: "Lora", serif;
    }

    .tagLine {
        font-size: 2em;
        margin: 40px 0;
    }

    .ctaButtonContainer {
        width: 60% !important;
        margin: 10px 0;
    }

    .ctaButton {
        width: 100% !important;
    }

    .mainContent {
        padding: 30px 0 40px 0;
    }

    .aboutSection p.aboutSectionContent {
        width: 100%;
        font-size: 20px;
    }

    .mainContent h1 span.mainContentHeading {
        font-size: 30px;
    }

    .serviceContainer .row {
        flex-direction: column;
        align-items: center;
    }

    .servicesDetails {
        width: 95%;
        margin: 0 20px;
    }

    .service {
        margin-top: 40px !important;
    }

    .row .featureBoxContainer {
        width: 100%;
        flex-direction: column;
    }

    .featureBox {
        width: 100% !important;
    }

    .featureIcon {
        width: 50px;
        height: 50px;
    }

    .featureTitle {
        font-size: 20px;
    }

    .marginLeft28 {
        margin-left: unset;
    }
}

@media (min-width: 576px) {
    .banner {
        align-items: center;
        font-family: "Lora", serif;
        /* height: 70vh; */
    }
}

@media only screen and (min-width: 600px) {
    .banner {
        align-items: center;
        font-family: "Lora", serif;
        /* height: 70vh; */
    }

}

@media (min-width: 768px) {
    .banner {
        /* justify-content: center; */
        align-items: center;
        font-family: "Lora", serif;
        /* height: 70vh; */
    }

}

@media only screen and (max-width: 992px) {

    .banner {
        align-items: center;
        font-family: "Lora", serif;
        /* height: 70vh; */
    }

    ul.servicesListContainer li {
        word-wrap: break-word;
    }

}

@media (min-width: 1200px) {
    .banner {
        align-items: center;
        font-family: "Lora", serif;
        /* height: 70vh; */
    }
}