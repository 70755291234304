.headerContainerNavBar{
    height: 70px;
    display: flex;
    align-items: center;
    background-color: white;
    color: var(--primary-color);
    position: fixed;
    font-family: "HelveticaNeue-Light";
}

.logoSection{
    width: 200px !important;
}

.brandLogo{
    width: 120px;
    height: 40px;
}

.logoTitleContainer{
    display: flex;
    align-items: center;
}

.links{
    width: auto !important;
    display: flex;
    align-items: center;
}

.headerRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.headerTitle{
    color: #fff;
    font-family: 'TypesauceRegular';
    font-size: 33px;
}

a{
    text-decoration: none !important;
}

.linkContainer{
    margin-left: 27px;
}

/* .links{
    flex: 1.5 0 !important;
} */

.resourcesSublinkContainer{ 
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    position: absolute; 
    background-color: #fff;
    width: 140px; 
    height: 100px; 
    border-radius: 20px;
    border: 1px solid #0D1B34;
    top: 70px;
}

a.resourcesSublink{
    width: 100%;
    border-bottom: 1px solid #000;
    padding: 10px 20px;
}

a.resourcesSublink:last-child{
    border-bottom: 0;
}

a>span.resourcesSublinkText{
    color: #000 !important;
    font-size: 16px !important;
}

.resourcesButton

button.resourcesButton, a>span.link{
    text-decoration: none;
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
}

.dropdownIcon{
    margin-left: 14px;
}

button.loginButton{
    background-color: transparent;
    border: none;
    margin: 20px 0;
}

button.loginButton span{
    color: #7593F1;
}

/* a>span.loginLink{
    color: #7593F1;
} */
a>span.loginLink:hover{
    color: green;
}

button.headerLoginButton{
    margin-left: 20px;
    background-color: #2196F3;
    height: 40px;
    border-radius: 22px;
    background-color: white;
    box-shadow: none;
    /* border: 2px var(--primary-color) solid; */
    outline: 2px solid var(--primary-color);
    /* outline-offset: -5px; */
}

button span.headerLoginButtonText{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-color);
  font-family: "HelveticaNeue-Light";
}

button.requestDemoBtn{
    margin-left: 10px;
    height: 40px;
    border-radius: 22px;
    background-color: var(--primary-color);
}

button span.requestDemoBtnText{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: "HelveticaNeue-Light";
}

.loginModalContainer{
    position: absolute;
    display: flex;
    margin: 0 auto;
    width: 100%;
}

@media only screen and (max-width: 992px){

    .navBarBrand{
        display: flex;
        align-items: center;
    }
    
    nav.navbar{
        background-color: #0D1B34 !important;
    }

    nav.navbar-dark button.navbar-toggler span.navbar-toggler-icon {
        background-image: url("../../assets/images/list.svg") !important;
        /* background-color: #fff; */
    }

    .navbar-nav{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navbar-nav a{
        color: #fff;
    }

    .navbar-nav .dropdown-menu{
        padding: 0 20px;
    }

    .navbar-nav .dropdown-menu a{
        color: #000;
    }
    
    .requestDemoBtn{
        margin: 0
    }
    
    .requestDemoBtn{
        width: 50%;
    }

    .requestDemoBtnText{
        font-size: 18px;
        font-family: "HelveticaNeue-Light";
    }

}

