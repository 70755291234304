.editHomeContainer{
    padding:  20px 0;
}

.sectionRow{
    margin-top: 20px;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px;
}

.headingRow input{
    padding: 10px;
}

.headingLabel{
    color: #fff;
}

form input{
    color: #000;
}